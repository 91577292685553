import daterangepicker from 'daterangepicker';

$.urlParam = function(name){
  var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
  if (results==null) {
     return null;
  }
  return decodeURI(results[1]) || 0;
}

$(document).ready(function() {
  $('input[name="daterange"]').daterangepicker({
    "startDate": $("#daterange_picker").length ? $("#daterange_picker").val().split(" ")[0] : '',
    "endDate": $("#daterange_picker").length ? $("#daterange_picker").val().split(" ")[2] : '',
    locale: {
      format: 'MM/DD/YYYY'
    },
    "maxSpan": {
      "days": 31
    },
  });
  
  $('input[name="daterange"]').on('apply.daterangepicker', function(ev, picker) {
    document.location.href = document.location.href.replace(/(\d{4}-\d{2}-\d{2})\/(\d{4}-\d{2}-\d{2})/g, picker.startDate.format('YYYY-MM-DD') + "/" + picker.endDate.format('YYYY-MM-DD')).replace(/start_date=\d{4}-\d{2}-\d{2}/g, 'start_date=' + picker.startDate.format('YYYY-MM-DD')).replace(/end_date=\d{4}-\d{2}-\d{2}/g, 'end_date=' + picker.endDate.format('YYYY-MM-DD'))
  });
  
  // FIXME: position of date range picker in mobile version
  $('#daterange_mobile').click(function() {
    $('input[name="daterange"]').trigger('click');
  });
  
  $('.mob-show-filter').click(function(event) {
  	$('.sidebar').fadeToggle();
  	event.preventDefault();
  });

  if ($(window).width() < 768) {
    $(document).click(function(e) {
        if (!$(e.target).is('.sidebar, .sidebar *, .mob-show-filter, .mob-show-filter *')) {
    			$('.sidebar').fadeOut();
        }
    });
  }
  
  $(".tags_checkboxes").click(function() { 
    var tags = '';
    $(".tags_checkboxes").each(function( index ) {
      if($(this).is(':checked')) tags += 'tags[]=' + $(this).val() + '&';
    });
    var url = window.location.href.split('?')[0];
    var order = $.urlParam('order');
    if ($.urlParam('order') == null) order = 2;
    document.location.href = url + '?order=' + order + '&' + tags;
  });
});